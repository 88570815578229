<template>
	<div>
		<div id="change-name-container">
			<h2>{{ languageStrings.editDisplayName }}</h2>
			<div class="input-container">
				<div v-if="serverBusy" class="loading">
					<span class="loading-icon"></span>
					<span class="loading-message">{{ busyText }}</span>
				</div>
				<label for="changeDisplayName">{{ languageStrings.currentDisplayName }}</label>
				<input
					type="text"
					id="changeDisplayName"
					name="changeDisplayName"
					@keydown.enter="changeDisplayName()"
					@input="setButtonState()"
					v-model="displayName"
					:placeholder="displayName?.length > 0 ? displayName : languageStrings.youMustEnterName"
					v-on:keydown.enter.prevent
				/>
				<!-- We need "keydown.enter.prevent" to stop ENTER key from attempting submit. Tries to submit to "/?phoneNumber=" -->
			</div>
			<div class="button-container">
				<button
					v-if="showSubmitButton"
					class="btn"
					type="button"
					:title="languageStrings.saveName"
					@click="changeDisplayName()"
				>
					{{ languageStrings.saveName }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "ChangeDisplayName",
	props: {
		userProfile: Object,
		playerState: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			busyText: "",
			serverBusy: false,
			showSubmitButton: false,
			displayName: this.userProfile.displayName,
		};
	},
	watch: {
		userProfile: {
			handler() {
				this.displayName = this.userProfile.displayName;
			},
		},
	},
	methods: {
		setButtonState() {
			this.showSubmitButton = this.displayName && this.userProfile.displayName !== this.displayName;
		},
		async changeDisplayName() {
			if (!this.displayName) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.displayNameFieldEmpty;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.serverBusy = true;
			this.busyText = this.languageStrings.updatingDisplayName;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					displayName: this.displayName,
				},
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.changeDisplayNameUnsuccessful;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.displayName = dataJson.userProfile.displayName;

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.displayNameChanged;
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				// this.eventBus.emit("closeChangeDisplayNameModal");
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		// close() {
		// 	this.eventBus.emit("closeChangeDisplayNameModal");
		// },
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	display: grid;
	align-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

#change-name-container {
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10001;
	color: #fff;
	overflow: hidden auto;
	text-align: center;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#change-name-container {
		margin-bottom: 100px;
	}
}

#change-name-container > div {
	margin: auto;
	width: 50vw;
}

#change-name-container fieldset {
	padding: 0;
}

.input-container,
.button-container {
	display: flex;
	max-width: 20em;
	align-items: center;
	margin: auto;
}

input {
	margin-bottom: 15px;
}

.button-container {
	flex-flow: row nowrap;
}

.input-container {
	flex-direction: column;
}

@media (min-width: 768px) {
	#change-name-container > div {
		/* padding: 30px; */
		width: 50%;
	}
}
</style>
