<template>
	<div id="nav" :class="devMode ? 'dev-mode' : ''">
		<nav aria-label="main menu">
			<ul>
				<li>
					<router-link :title="languageStrings.home" to="/" @click="toggleNavBar()">{{
						languageStrings.home
					}}</router-link>
				</li>
				<li v-if="!playerState.loggedIn && countryCode === 'MX'">
					<router-link :title="languageStrings.register" to="/extendedRegisterView" @click="toggleNavBar()">
						{{ languageStrings.register }}
					</router-link>
				</li>
				<li v-if="!playerState.loggedIn && countryCode === 'US'">
					<router-link :title="languageStrings.register" to="/multiComponentRegisterView" @click="toggleNavBar()">
						{{ languageStrings.register }}
					</router-link>
				</li>

				<li v-if="playerState.loggedIn && systemSettings?.features?.allowWebBuyIn">
					<router-link :title="languageStrings.webPayCashIn" to="/webPayCashIn" @click="toggleNavBar()">{{
						languageStrings.webPayCashIn
					}}</router-link>
				</li>
				<li
					v-if="
						playerState.loggedIn &&
						playerState?.casinoBalances?.length > 0 &&
						systemSettings?.features?.allowWebCashOut
					"
				>
					<router-link :title="languageStrings.webPayCashOut" to="/webPayCashOut" @click="toggleNavBar()">{{
						languageStrings.webPayCashOut
					}}</router-link>
				</li>
				<li v-if="playerState.loggedIn && systemSettings?.features?.allowWebCashOut">
					<router-link
						:title="languageStrings.webPayTransactions"
						to="/webPayTransactions"
						@click="toggleNavBar()"
						>{{ languageStrings.webPayTransactions }}</router-link
					>
				</li>
				<li v-if="playerState.loggedIn">
					<router-link :title="languageStrings.editPlayerProfile" to="/userProfile" @click="toggleNavBar()">{{
						languageStrings.editPlayerProfile
					}}</router-link>
				</li>
				<li v-if="playerState.loggedIn">
					<router-link :title="languageStrings.changePassword" to="/changePassword" @click="toggleNavBar()">{{
						languageStrings.changePassword
					}}</router-link>
				</li>
				<li v-if="playerState.loggedIn && systemSettings?.features?.isIdCheckSupported && countryCode === 'MX'">
					<router-link
						:title="languageStrings.identification"
						to="/identificationStatus"
						@click="toggleNavBar()"
						>{{ languageStrings.identification }}</router-link
					>
				</li>
				<li v-if="devMode">
					<router-link title="SystemInfo" to="/systemInfo" @click="toggleNavBar()">SystemInfo</router-link>
				</li>
				<li>
					<router-link :title="languageStrings.about" to="/about" @click="toggleNavBar()">{{
						languageStrings.about
					}}</router-link>
				</li>
				<li v-if="isMobile && playerState?.loggedIn" style="text-align: center">
					<button class="btn" title="Logout" target="_blank" @click="logout()">
						{{ languageStrings.logout }}
					</button>
				</li>
			</ul>
			<span id="loading-icon"></span>
			<p v-if="devMode" class="dev-mode">Client running in development mode</p>
		</nav>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "MainNavbar",
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		playerState: Object,
		isMobile: Boolean,
		systemSettings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
		};
	},
	watch: {},
	methods: {
		toggleNavBar() {
			this.eventBus.emit("hideShowHeaderFooter", false);
			this.eventBus.emit("toggleNavBar");
		},
		logout() {
			this.eventBus.emit("hideShowHeaderFooter", false);
			this.eventBus.emit("regularLogout");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dev-mode {
	position: absolute;
	bottom: 0;
	width: 100%;
	color: #f00;
	text-align: center;
	font-weight: bold;
	background-color: white;
}

#nav {
	position: fixed;
	top: 79px;
	left: 0;
	bottom: 0;
	max-height: 0;
	width: 100%;
	background-color: #bccfe5;
	overflow: hidden auto;
	list-style: none;
	padding: 0;
	transition: max-height 0.3s;
	z-index: 1000;
	/* box-shadow: 0px 3px 3px rgb(0 0 0 / 50%); */
}

#nav.open {
	max-height: 80vh;
}

/* #nav.open.dev-mode {
	max-height: 360px;
} */

#nav a {
	font-weight: bold;
}

#nav button {
	display: block;
	width: calc(100% - 20px);
	margin: 10px;
	padding: 10px;
	font-weight: bold;
}

#nav a.router-link-exact-active {
	color: #100f2e;
}

nav ul {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;
	padding: 0;
	/* background-color: #bccfe5; */
	list-style: none;
	overflow: hidden;
}

nav a {
	display: block;
	background-color: #5b88c0;
	margin: 10px;
	padding: 10px;
	border-radius: 6px;
	text-decoration: none;
	transition: 0.3s;
	color: #dceeff;
	user-select: none;
	cursor: pointer;
	/* box-shadow: inset -1px -1px 15px 0px rgb(0 0 0 / 40%); */
}

nav a:hover,
.login-link:hover {
	transform: scale(1.025);
	background-color: #4971a4;
	color: white;
}

.router-link-active,
.router-link-active:hover {
	transform: scale(1);
 	/* background-color: green; */
	box-shadow: inset 0px 0px 14px rgb(0 0 0 / 44%);
	cursor: default;
}

@media (min-width: 768px) {
	#nav {
		position: relative;
		top: unset;
		width: 20%;
		float: left;
		height: calc(100vh - 158px);
		max-height: unset;
		/* box-shadow: 1px 0px 6px rgb(0 0 0 / 50%); */
		z-index: unset;
	}

	#nav.open,
	#nav.open.dev-mode {
		max-height: unset;
	}
}
</style>
