<template>
	<link
		v-if="countryCode !== 'MX'"
		rel="stylesheet preconnect"
		as="style"
		href="https://fonts.googleapis.com/css?family=Roboto&amp;display=block"
	/>
	<link
		v-if="countryCode === 'MX'"
		rel="stylesheet preconnect"
		as="style"
		href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&display=swap"
	/>
	<Header
		:playerState="playerState"
		v-if="!headerFooterHidden"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
		:isMobile="isMobile"
	/>
	<div id="nav-container" :class="isMobile ? 'isMobile' : ''">
		<button id="navbar-button" v-if="isMobile" @click="toggleNavBar()" class="nav-button-container btn">
			<div></div>
			<div></div>
			<div></div>
		</button>
		<div v-if="!mainNavIsHidden">
			<MainNavbar
				:class="navBarClass"
				:playerState="playerState"
				:isMobile="isMobile"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
				:systemSettings="systemSettings"
			/>
		</div>
	</div>
	<div v-if="playerState?.loggedIn && countryCode != 'US'">
		<button
			class="btn"
			:class="isMobile ? 'hide-navbar' : 'show-navbar toggle-nav-button-container'"
			@click="hideNavBar()"
		>
			<div v-if="!mainNavIsHidden"><</div>
			<div v-else>></div>
		</button>
	</div>
	<Login
		v-if="displayLogon"
		:isMobile="isMobile"
		:hideLoginForm="hideLoginForm"
		:playerState="playerState"
		:capsLockOn="capsLockOn"
		:serverRules="serverRules"
		:casinoList="casinoList"
		:systemSettings="systemSettings"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
	/>
	<router-view
		:id="mainNavIsHidden ? 'main-nav-hidden' : 'router-view'"
		:key="$route.fullPath"
		:playerState="playerState"
		:casinoList="casinoList"
		:serverRules="serverRules"
		:capsLockOn="capsLockOn"
		:isMobile="isMobile"
		:serverVersion="serverVersion"
		:systemSettings="systemSettings"
		:countryList="countryList"
		:verificationCode="verificationCode"
		:userProfile="userProfile"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
		:systemCurrencyTool="systemCurrencyTool"
		:mainNavIsHidden="mainNavIsHidden"
	/>
	<DocumentDisplay
		v-if="playerState?.newDocuments?.length > 0"
		:playerState="playerState"
		:isMobile="isMobile"
		:languageStrings="languageStrings"
		:languageErrorStrings="languageErrorStrings"
		:newDocuments="playerState?.newDocuments"
		:documentList="documentList"
	/>
	<div v-if="countryCode !== 'MX'">
		<Footer :serverVersion="serverVersion" v-if="!headerFooterHidden && !isMobile" />
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import store from "@/store/index";
import Header from "@/components/Header";
import MainNavbar from "@/components/MainNavbar";
import Login from "@/components/Login";
import Footer from "@/components/Footer";
import router from "@/router";
import CurrencyTool from "@/dependencies/currencyTool";
import DocumentDisplay from "@/components/DocumentDisplay";
// https://www.npmjs.com/package/country-codes-list
const countryCodes = require("country-codes-list");

export default {
	components: {
		Login,
		MainNavbar,
		Header,
		Footer,
		DocumentDisplay,
	},
	data() {
		return {
			languageStrings: require(`@/dependencies/${this.countryCode}.json`),
			languageErrorStrings: require(`@/dependencies/errors-${this.countryCode}.json`),
			windowWidth: window.innerWidth,
			isMobile: this.windowWidth < 768,
			navBarClass: "",
			status: Object.assign({}, this.globalStatus),
			playerState: {},
			messageResetTimer: null,
			hideLoginForm: false,
			serverVersion: {},
			casinoList: [],
			casinosIncludingDeleted: [],
			capsLockOn: false,
			serverRules: {
				captchaType: "",
				captchaSiteKey: "",
				passwordRules: {
					minimumLength: 0,
					minimumNumberOfCharacterClasses: 0,
				},
			},
			systemSettings: {},
			headerFooterHidden: false,
			countryList: [],
			countryCodesObject: countryCodes.customList("countryNameEn", "{countryCode}: {countryCallingCode}"),
			verificationCode: null,
			displayLogon: true,
			userProfile: {},
			inGameCurrencyTool: null,
			systemCurrencyTool: null,
			documentList: [],
			documentsRequired: [],
			documentString: "",
			documentMIME: "",
			documentAcknowlegements: [],
			newDocumentsAvailable: false,
			newDocuments: [],
			existingDocumentKeys: [],
			documentLangAvalable: [],
			mainNavIsHidden: false,
		};
	},
	watch: {
		windowWidth() {
			this.onResize();
		},
		$route(to) {
			switch (to.name) {
				case "RegisterNewUser":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				case "MultiComponentRegisterView":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				case "ExtendedRegisterView":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				case "VerifyEmail":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				case "About":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				case "SystemInfo":
					this.hideLoginForm = !this.playerState?.loggedIn;
					break;
				default:
					this.hideLoginForm = false;
			}
		},
	},
	async created() {
		this.playerState = this.session.get("playerApp");

		this.checkServerVersion();
		await this.getSystemSettings();
		this.checkPasswordRequirements();
		this.getCasinos();
		this.makeCountryArray();
		this.documentList = await this.listDocuments(this);
		this.existingDocumentKeys = this.getDocumentKeys(this.documentList);
		if (this?.playerState?.loggedIn) {
			this.getUserProfile();
			this.checkIfNewDocumentsAvaiable();
		}

		// if (Object.keys(this.playerState).length === 0 || !this.playerState.loggedIn) {
		// 	router.push("/");
		// }
		this.eventBus.on("listDocumentAcknowlegement", async (payload) => {
			this.checkIfNewDocumentsAvaiable(payload);
		});
		this.eventBus.on("closeDocumentsDisplay", () => {
			this.newDocumentsAvailable = false;
		});
		this.eventBus.on("toggleNavBar", (forceClose = false) => {
			if (forceClose) {
				this.mainNavbarOpen = false;
				this.navBarClass = "";
			} else {
				this.toggleNavBar();
			}
		});
		this.eventBus.on("countrySelected", (payload) => {
			this.playerState.playersCountry = payload;
		});
		this.eventBus.on("updateUserProfile", () => {
			if (this?.playerState?.loggedIn) this.getUserProfile();
		});
		this.eventBus.on("updatePlayerState", (payload) => {
			let mergedPlayerState = {};
			if (Object.keys(payload).length !== 0) {
				mergedPlayerState = {
					...this.playerState,
					...payload,
				};
			}
			this.inGameCurrencyTool = new CurrencyTool(this.playerState.currencyInfo, this.localeString);
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			this.playerState = mergedPlayerState;
			this.session.save(mergedPlayerState);
			store.dispatch("setPlayerState", mergedPlayerState);
		});
		this.eventBus.on("hideShowHeaderFooter", (hidden = false) => {
			this.headerFooterHidden = hidden;
		});
	},
	mounted() {
		const cssFile = `./styleOverrides/${this.countryCode}.css`; // load style overides for locale customizations
		const cssElement = document.createElement("link");
		cssElement.setAttribute("rel", "stylesheet");
		cssElement.setAttribute("type", "text/css");
		cssElement.setAttribute("href", cssFile);
		document.getElementsByTagName("head")[0].appendChild(cssElement);

		let thisInstance = this;
		this.$nextTick(() => {
			window.addEventListener("resize", thisInstance.onResize);
			thisInstance.onResize();
		});
		let elem = document.getElementsByTagName("body");
		elem[0].addEventListener("keyup", function (e) {
			// try/catch to fix a stupid keyup getting triggered when you click anywhere on the page
			// only happend the first time you click on the page
			// might look into this more later to see what causing this
			try {
				thisInstance.capsLockOn = e.getModifierState("CapsLock");
			} catch (e) {
				console.log(e);
			}
		});
		window.onpopstate = (e) => {
			// Force browser back button to home
			// Web history state has problems when we deploy in a subfolder
			// This is to fix the back button causing empty page to be displayed
			thisInstance.$router.push("/");
		};
		this.resolveQueryParams();
	},
	methods: {
		async checkIfNewDocumentsAvaiable(documentAcknowlegement = []) {
			let _state = this.playerState;

			if (documentAcknowlegement.length > 0) {
				_state.documentAcknowlegements = this.documentAcknowlegements = documentAcknowlegement;
			} else {
				_state.documentAcknowlegements = this.documentAcknowlegements = await this.listDocumentAcknowlegement(
					this,
					this.languageErrorStrings
				);
			}

			this.newDocuments = [];
			let matchedDocs = [];
			let matchedLanguage = [];
			this.existingDocumentKeys.forEach((existingKey) => {
				// First check to see if a dcoument matching the locale string exists
				matchedLanguage = this.documentList.filter((doc) =>
					doc.language.toLowerCase().includes(this.localeString.toLowerCase())
				);

				// If no matching language, match by the key
				if (matchedLanguage.length > 0) {
					matchedDocs = matchedLanguage.filter((doc) => doc.key === existingKey);
				} else {
					matchedDocs = this.documentList.filter((doc) => doc.key === existingKey);
				}

				console.log(this.documentList);

				let highestVersion = matchedDocs.sort((a, b) => a.version < b.version)[0];

				let docToRemove = {};
				if (typeof highestVersion !== "undefined") {
					docToRemove = this.documentAcknowlegements.filter(
						(obj) => obj.version === highestVersion.version && obj.key === highestVersion.key
					)[0];
				}

				let isDuplicateVersion = false;
				if (typeof docToRemove !== "undefined") {
					isDuplicateVersion =
						docToRemove?.key === highestVersion?.key && docToRemove?.version === highestVersion?.version;
				}

				if (!isDuplicateVersion) this.newDocuments.push(highestVersion);
			});

			let debug = this.newDocuments;

			if (this.newDocuments.length > 0) {
				_state.newDocumentsAvailable = this.newDocumentsAvailable = true;
			}
			_state.newDocuments = this.newDocuments;

			console.log(this.newDocuments);

			this.eventBus.emit("updatePlayerState", _state);
		},
		resolveQueryParams() {
			let location = window.location;
			let urlParams = null;

			if (location.hash) {
				location = new URL(location.href.replace("#/", ""));
				urlParams = new URLSearchParams(location.search);
			} else {
				urlParams = new URLSearchParams(location.search);
			}

			for (const [key, value] of urlParams) {
				if (key === "code") this.verificationCode = value;
			}
		},
		async getUserProfile() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingProfileInformation;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL(`/api/v1/user/${this.playerState.userId}/profile`, this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState?.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				this.userProfile = dataJson;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = "Server not responding to User Profile request";
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		makeCountryArray() {
			// Makes an array of country abjects from countryCodesObject
			let array = [];
			for (const property in this.countryCodesObject) {
				let country = {
					name: property,
					countryCode: this.countryCodesObject[property].split(":")[0],
					countryPrefix: Number(this.countryCodesObject[property].split(": ")[1]),
				};
				array.push(country);
			}
			array.sort((a, b) => a.name.localeCompare(b.name));
			this.countryList = array;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			this.isMobile = this.windowWidth < 768;
			this.mainNavbarOpen = false;
			this.navBarClass = "";
		},
		toggleNavBar() {
			this.mainNavbarOpen = this.mainNavbarOpen ? false : true;
			this.navBarClass = this.mainNavbarOpen ? "open" : "";
			this.mainNavIsHidden = false;
		},
		hideNavBar() {
			this.mainNavIsHidden = !this.mainNavIsHidden;
		},
		async getSystemSettings() {
			let requestUrl = new URL("/api/v1/system/settings", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.systemSettings = dataJson;
				this.eventBus.emit("serverSettingsLoaded", dataJson);

				this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			} catch (e) {
				console.error(e);
				console.error("Server not responding to System Settings request");
			}
		},
		async checkServerVersion() {
			let requestUrl = new URL("/api/v1/version", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.somethingWentWrongAttemptingToConnectToServerPleaseTryAgainLater;
					this.eventBus.emit("updateStatus", this.status);
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.serverVersion = dataJson;
			} catch (e) {
				console.error(e);
				console.error("Server not responding to version request");
			}
		},
		async checkPasswordRequirements() {
			let requestUrl = new URL("/api/v1/user/register/rules", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.serverRules = dataJson;
			} catch (e) {
				console.error(e);
			}
		},
		async getCasinos() {
			try {
				let response = await sharedScripts.getCasinoList(this);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				this.casinoList = await response.sort((a, b) => a.name.localeCompare(b.name));

				this.casinoList.forEach((casino) => {
					// Insert an instance of the currency tool class specific to each casino's in-game currency rules.
					casino.currencyTool = new CurrencyTool(casino.currencyInfo, this.localeString);
				});

				store.dispatch("setCasinoList", this.casinoList || []);
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
			}
		},
		listAcknowledgements() {},
	},
};
</script>

<style>
h1,
h2 {
	text-align: center;
}
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden auto;
	color: #fff;
	background-color: #32373f;
}

.gradient-background {
	/* background: linear-gradient(31deg, #e90ef2, #3bcf5f, #76b6ea, #11f1ea); */
	background-size: 240% 240%;
	/* animation: gradient-animation 8s ease infinite; */
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.loading-icon {
	display: block;
	content: "";
	position: relative;
	top: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 1px solid;
	border-radius: 100%;
	border-color: red white blue black;
	animation: loader 0.5s linear infinite;
}

#paging {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 80%;
	justify-content: center;
	margin: auto;
	user-select: none;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

#router-view {
	position: fixed;
	top: 78px;
	width: 100%;
	padding: 15px 0;
	overflow: hidden auto;
	height: calc(100vh - 200px);
}

input,
label {
	margin: 5px;
}

input {
	color: #000;
	font-weight: 700;
}

input,
select,
textarea,
input:focus,
select:focus,
textarea:focus {
	font-size: 16px;
}

input:invalid {
	border: 4px red solid;
}

legend {
	margin: auto;
	padding: 0 15px;
	font-size: 1.75em;
	text-align: center;
}

#videoCheckIframe iframe #backButton {
	display: none !important;
}

.nav-button-container {
	position: fixed !important;
	top: 15px;
	left: 15px !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	padding: 0;
	margin: 0;
	border-radius: 100% !important;
	overflow: hidden;
}

#nav-container {
	position: fixed;
	top: 81px;
	width: 100%;
}

#nav-container.isMobile {
	position: relative;
	z-index: 1010;
}

.nav-button-container div {
	margin: 3px;
	align-self: center;
	width: 30px;
	height: 4px;
	background-color: #fff;
	border-radius: 5px;
}

button {
	font-size: inherit;
}

.back,
button {
	user-select: none;
	padding: 5px 10px;
	margin: 10px auto;
	border-radius: 8px;
	cursor: pointer;
}

.btn {
	position: relative;
	padding: 10px;
	right: 15px;
	left: 0;
	background-color: #5b88c0;
	color: #fff;
	border-radius: 15px;
	/* box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%); */
	border: 1px #fff solid;
	transition: box-shadow 0.2 ease-in-out, transform 0.2s ease-in-out;
	cursor: pointer;
}

.center {
	display: block;
	text-align: center;
}

/* .country-flag {
	border: 1px #000 solid;
} */

.btn:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}

.login-button {
	position: absolute;
	top: 5px;
	left: unset;
	right: 15px;
	width: 10em;
	z-index: 500;
}

.password-container {
	display: flex;
	justify-content: flex-start;
	position: relative;
}

.password-container span {
	position: absolute;
	right: 8px;
	top: 5px;
	color: #000;
	cursor: pointer;
	user-select: none;
}

iframe {
	margin: auto;
	display: block;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.password-container span {
		top: 8px;
	}
	.eye-span {
		margin-top: 10px;
	}
}

.password-container span::after {
	content: "I";
	position: absolute;
	right: 8px;
	top: -7px;
	transform: rotate(45deg);
	font-size: 2em;
	font-weight: bold;
}

.password-container .eye {
	width: 1.5em;
	height: auto;
}

.password-container span.hidden {
	color: #008000;
}

.password-container span.hidden::after {
	content: none;
}

.password-container input {
	flex: 1 0 auto;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
	transition: transform 0.25s ease;
}

.slide-enter-from,
.slide-leave-to {
	transform: translateY(100vh);
}

.slidedown-enter-active,
.slidedown-leave-active {
	transition: height 0.25s ease;
}

.slidedown-enter-from,
.slidedown-leave-to {
	height: 0;
}

.score-words {
	font-weight: bold;
	text-transform: uppercase;
}

.score-words.weak {
	color: #f00;
}

.warn,
.score-words.medium {
	color: #ff0;
}

.score-words.strong {
	color: #0f0;
}

.suspended {
	margin: 15px auto;
	padding: 10px;
	color: #fff;
	background: #f00;
	text-align: center;
	border: 1px #000 solid;
	border-radius: 12px;
}

hr.thicker-radius {
	border: 2px solid rgb(255 255 255 / 20%);
	border-radius: 2px;
}

.sfc-main-container .main-title {
	color: #fff !important;
	text-align: center;
}

/* Styling an indeterminate progress bar */
/* Thanks CSS Tricks: https://css-tricks.com/html5-progress-element/ */

progress[value] {
	appearance: none;
	border: none;
	width: 100%;
	height: 20px;
	background-color: whiteSmoke;
	border-radius: 3px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
	position: relative;
	margin: 0 0 1.5em;
}

/*
Webkit browsers provide two pseudo classes that can be use to style HTML5 progress element.
-webkit-progress-bar -> To style the progress element container
-webkit-progress-value -> To style the progress element value.
*/

progress[value]::-webkit-progress-bar {
	background-color: whiteSmoke;
	border-radius: 3px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-webkit-progress-value {
	position: relative;
	background-size: 35px 20px, 100% 100%, 100% 100%;
	border-radius: 3px;
	animation: animate-stripes 5s linear infinite;
}

@keyframes animate-stripes {
	100% {
		background-position: -100px 0;
	}
}

progress[value]::-webkit-progress-value:after {
	/* Only webkit/blink browsers understand pseudo elements on pseudo classes. */
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: 7px;
	right: 7px;
	background-color: white;
	border-radius: 100%;
}

.custom-bar.weak::-webkit-progress-value {
	/* Gradient background with Stripes */
	background-image: -webkit-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-webkit-linear-gradient(left, rgb(128, 0, 0), rgb(128, 0, 0), orange, yellow);
}

.custom-bar.medium::-webkit-progress-value {
	/* Gradient background with Stripes */
	background-image: -webkit-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-webkit-linear-gradient(left, rgb(128, 0, 0), yellow, green);
}

.custom-bar.strong::-webkit-progress-value {
	/* Gradient background with Stripes */
	background-image: -webkit-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-webkit-linear-gradient(left, rgb(128, 0, 0), green, green);
}

/* Similarly, for Mozillaa. Unfortunately combining the styles for different browsers will break every other browser. Hence, we need a separate block. */

.custom-bar.weak::-moz-progress-bar {
	/* Gradient background with Stripes */
	background-image: -moz-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-moz-linear-gradient(left, rgb(128, 0, 0), rgb(128, 0, 0), orange, yellow);
}

.custom-bar.medium::-moz-progress-bar {
	background-image: -moz-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-moz-linear-gradient(left, rgb(128, 0, 0), yellow, green);
}

.custom-bar.strong::-moz-progress-bar {
	background-image: -moz-linear-gradient(
			135deg,
			transparent,
			transparent 33%,
			rgba(0, 0, 0, 0.1) 33%,
			rgba(0, 0, 0, 0.1) 66%,
			transparent 66%
		),
		-moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-moz-linear-gradient(left, rgb(128, 0, 0), green, green);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.hide-navbar {
	display: none;
	position: absolute;
}

#main-nav-hidden {
	width: 100%;
	margin-left: 0;
	background-color: white;
	height: 130vh;
}

.show-navbar {
	margin-top: 7.5vh;
	color: white;
	position: relative;
	z-index: 1010;
	width: 3vw;
	margin-right: 95%;
}

.toggle-nav-button-container {
	position: fixed !important;
	top: 2vh;
	left: 15px !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	/* width: 30px;
	height: 30px; */
	/* padding: 0; */
	margin: 0;
	/* border-radius: 100% !important; */
	/* overflow: hidden; */
	align-items: center;
}

@media (min-width: 768px) {
	#router-view {
		width: 80%;
		margin-left: 20%;
		height: calc(100vh - 188px);
	}
}

@media (max-width: 767px) {
	#app {
		height: 100vh;
	}
}
</style>
